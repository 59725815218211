var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "main" }, [
    _c("div", { staticClass: "title" }, [_vm._v("维权援助")]),
    _c("div", { staticClass: "sep" }),
    _c("div", { staticClass: "content" }, [
      _c("span", [_vm._v("正在跳转页面：")]),
      _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
        _vm._v("中国知识产权维权援助网 (ipwq.cn)")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }