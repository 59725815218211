<template>
  <el-main class="main">
    <div class="title">维权援助</div>
    <div class="sep" />
    <div class="content">
      <span>正在跳转页面：</span
      ><a :href="url" target="_blank">中国知识产权维权援助网 (ipwq.cn)</a>
    </div>
  </el-main>
</template>

<script>
import store from "@/store";
export default {
  name: "help",
  data() {
    return {
      url: "http://www.ipwq.cn",
    };
  },
  beforeRouteEnter(to, from, next) {
    store.commit("service/SET_ACTIVEINDEX", { activeIndex: "4" });
    next((vm) => {
      window.open(vm.url, "_blank");
    });
  },
};
</script>

<style lang="stylus" scoped>
.main {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 72px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    padding-top: 22px;

    span {
      font-size: 16px;
      font-family: PingFang-SC;
      font-weight: 400;
      color: #222222;
      line-height: 28px;
    }

    a {
      font-size: 16px;
      font-family: PingFang-SC;
      font-weight: 400;
      line-height: 21px;
      text-decoration: none;
      color: #2C53B0;
    }
  }
}
</style>